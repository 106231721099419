function TrackListShimmer() {
  return (
    <div className="animate-pulse">
      <div className="flex items-center justify-center  gap-2">
        <div className="h-10 w-10 shrink-0 rounded bg-white/10" />
        <div className="w-full space-y-2">
          <div className="h-3 w-10/12 rounded bg-white/10" />
          <div className="h-3 w-10 rounded bg-white/10" />
        </div>

        <div className="h-3 w-2/12 rounded bg-white/10" />
        <div className="h-3 w-10 rounded bg-white/10" />
        <div className="h-3 w-10 rounded bg-white/10" />
      </div>
    </div>
  );
}

export default TrackListShimmer;
