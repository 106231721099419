import PlayerStore from "../stores/PlayerStore";
import { Pause, Play } from "@phosphor-icons/react";

function MusicPlayer() {
  const { song, isPlaying, playOrPause } = PlayerStore();

  return (
    <div className="animate-in slide-in-from-bottom fixed bottom-0 left-0 right-0 h-20 w-full bg-gradient-to-t from-black px-4 transition-all duration-700">
      <div className="m-auto flex h-14 max-w-sm items-center justify-between gap-4 rounded-lg shadow-lg bg-[#1e1e1e]">
        <div className="flex w-full items-center gap-2">
          <div className="relative ml-2 flex h-10 w-10 overflow-hidden rounded">
            <div className="h-full w-full">
              <img
                alt="album cover"
                src={`https://e-cdns-images.dzcdn.net/images/cover/${song.ALB_PICTURE}/40x40-000000-80-0-0.jpg`}
                width="40"
                height="40"
              />
            </div>
          </div>
          <div className="flex max-w-[250px] flex-col text-left leading-7">
            <span className="truncate text-sm font-normal text-black dark:text-white">
              {song.SNG_TITLE}
            </span>
            <div className="truncate gap-1 inline-flex items-center text-white/50">
              {song.EXPLICIT_LYRICS == 1 && (
                <div className="text-xs font-semibold flex items-center justify-center bg-white/50 size-4 rounded-sm text-black p-[4px]">
                  E
                </div>
              )}

              <span className="text-sm">
                {song.ARTISTS.map((artist) => artist.ART_NAME).join(", ")}
              </span>
            </div>
          </div>
        </div>
        <div className="mr-4 flex items-center justify-center text-white">
          <button
            onClick={playOrPause}
            type="button"
            className="rounded-full bg-primary-500 p-2"
          >
            {isPlaying ? (
              <Pause className="h-4 w-4" weight="fill" />
            ) : (
              <Play className="h-4 w-4" weight="fill" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default MusicPlayer;
