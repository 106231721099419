import { create } from "zustand";
import axios from "axios";
// eslint-disable-next-line import/no-unresolved
import { toast } from "sonner";
import { BASE_URL } from "../utils/Constants";

const PlayerStore = create((set) => ({
  songs: [],
  song: null,
  isPlaying: false,
  player: new Audio(),
  auth: null,
  isLoading: false,

  addSongs: (songs) => {
    set({ songs: songs });
  },

  getAuth: async () => {
    const response = await axios.get(`${BASE_URL}/deezer/auth`);
    set((state) => {
      state.auth = response.data;
      return {};
    });
  },

  getSongs: async (term) => {
    set(async (state) => {
      state.isLoading = true;
      await axios
        .get(`${BASE_URL}/deezer/search`, {
          timeout: 5000,
          params: {
            filter: "all",
            query: term,
            nb: 50,
            output: "TRACK",
            start: 0,
            session: state.auth.session,
            checkForm: state.auth.checkForm,
          },
        })
        .then((res) => {
          const songs = res.data.data;
          set((state) => {
            state.songs = songs;
            return {};
          });
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            classNames: { toast: "bg-rose-700" },
          });
        })
        .finally(() => {
          set((state) => {
            state.isLoading = false;
            return {};
          });
        });
    });
  },

  playSong: (song) => {
    set((state) => {
      if (state.isPlaying) {
        state.player.pause();
      }
      state.isPlaying = true;
      state.player.src = song.MEDIA[0].HREF;
      state.player.load();
      state.player.play();
      return { song: song };
    });
  },

  playOrPause: () => {
    set((state) => {
      if (state.isPlaying) {
        state.player.pause();
      } else {
        state.player.play();
      }
      state.isPlaying = !state.isPlaying;
      return {};
    });
  },
}));

export default PlayerStore;
