import InputSearch from "./components/InputSearch";
import { useState, useEffect } from "react";
import TrackList from "./components/TrackList";
import MusicPlayer from "./components/MusicPlayer";
import PlayerStore from "./stores/PlayerStore";
// eslint-disable-next-line import/no-unresolved
import { Toaster } from "sonner";

function App() {
  // const [isLoading, setIsLoading] = useState([]);
  const [query, setQuery] = useState("");
  const { song, songs, addSongs, auth, getAuth, getSongs, isLoading } =
    PlayerStore();

  async function searchSongs(value) {
    setQuery(value);
    if (!value) {
      return addSongs([]);
    }
    if (!auth) {
      await getAuth();
    }
    await getSongs(value);
  }

  return (
    <div className="bg-black w-screen h-screen">
      <div className="scrollbar-thumb-rounded-full scrollbar-thin scrollbar-track-rounded-full scrollbar-thumb-[#111111] scrollbar-track-black  overflow-y-scroll flex h-full flex-shrink-0 overflow-auto bg-black">
        <div className="relative flex w-full flex-1 flex-col items-center p-0">
          <div className="z-10 box-border h-full w-full px-4 text-center">
            <div className="mx-auto my-10 w-full max-w-lg">
              <InputSearch onChange={searchSongs} />
            </div>
            <div className="container relative m-auto my-14 w-full">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold  leading-[32px] dark:text-white">
                  {query
                    ? `Resultados para '${query}'`
                    : "Pesquise por uma música"}
                </h1>
                {songs.length && (
                  <p className="text-sm text-gray-500 dark:text-secundary-200">
                    {songs.length} Músicas
                  </p>
                )}
              </div>
            </div>
            <TrackList tracks={songs} isLoading={isLoading} />
            {song && <MusicPlayer />}
          </div>
          <div className="absolute z-0 h-[220px] w-full bg-gradient-to-b from-rose-500/30" />
        </div>
      </div>
      <Toaster theme="dark" position="top-right" />
    </div>
  );
}

export default App;
