import { Clock, MusicNotesPlus } from "@phosphor-icons/react";
import TrackListItem from "./TrackListItem";
import TrackListShimmer from "./TrackListShimmer";

function TrackList({ tracks, isLoading }) {
  return (
    <div>
      <div
        className={`container relative m-auto w-full cursor-default ${!isLoading && tracks.length > 0 ? "pb-[100px]" : "pb-2"}`}
      >
        <header className="sticky top-0 z-10 flex h-[48px] items-center border-b p-2 leading-7  border-white/10 bg-black text-gray-50">
          <div className="mr-4 flex w-[30px] min-w-0 items-center justify-end">
            #
          </div>
          <div className="flex min-w-0 flex-1 items-center justify-start">
            Música
          </div>
          <div className="ml-8 hidden w-[15%] min-w-0 items-center justify-start lg:flex">
            Album
          </div>
          <div className="ml-8 flex w-[40px] min-w-0 items-center justify-start">
            <Clock className="h-5 w-5" />
          </div>
          <div className="flex w-[40px] min-w-0 items-center justify-end">
            <MusicNotesPlus className="h-5 w-5" />
          </div>
        </header>
        <div className="relative h-full w-full overflow-y-auto will-change-transform">
          {tracks.map((track, index) => (
            <TrackListItem track={track} index={index} key={index} />
          ))}
        </div>
      </div>

      {isLoading == true && (
        <div className="container relative m-auto w-full cursor-default space-y-2">
          {Array.from({ length: 25 }, (_, i) => (
            <TrackListShimmer key={i} />
          ))}
        </div>
      )}

      {tracks.length == 0 && (
        <div className="container relative m-auto w-full cursor-default pb-2">
          <div className="text-white py-5">Nenhuma música encontrada</div>
        </div>
      )}
    </div>
  );
}

export default TrackList;
