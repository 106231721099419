import { useState, useCallback } from "react";
import debounce from "lodash.debounce";

function InputSearch({ onChange }) {
  const [value, setValue] = useState("");

  const debounced = useCallback(
    debounce((value) => {
      onChange(value);
    }, 500),
    [],
  );

  const handleChange = (e) => {
    const { value: value } = e.target;
    setValue(value);
    debounced(value);
  };

  const clearInput = () => {
    setValue("");
    handleChange({ target: { value: "" } });
  };

  return (
    <div className="box-border flex h-[50px] items-center rounded-lg  px-2 bg-white/5">
      <div className="relative mr-4 flex h-full flex-1 items-center pl-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 256 256"
          width="1em"
          height="1em"
          fill="currentColor"
          className="h-5 w-5 text-white/50"
        >
          <g>
            <path d="M229.66,218.34l-50.07-50.06a88.11,88.11,0,1,0-11.31,11.31l50.06,50.07a8,8,0,0,0,11.32-11.32ZM40,112a72,72,0,1,1,72,72A72.08,72.08,0,0,1,40,112Z" />
          </g>
        </svg>
        <div className="absolute inset-x-0 inset-y-0 bg-transparent">
          <input
            value={value}
            onChange={handleChange}
            placeholder="Pesquisar musica"
            className="h-full w-full appearance-none border-transparent bg-transparent pl-[50px] pr-8 text-base outline-none placeholder:text-gray-300 text-white placeholder:text-white/50"
          />
        </div>
        {value && (
          <button onClick={clearInput} className="absolute right-0 z-20" d="">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 256"
              width="1em"
              height="1em"
              fill="currentColor"
              className="h-5 w-5 text-white/50"
            >
              <g>
                <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm37.66,130.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32L139.31,128Z" />
              </g>
            </svg>
          </button>
        )}
      </div>
    </div>
  );
}

export default InputSearch;
